const { shapes } = require('./shapes');

const imageNotAvailablePath = '/static/img/image-not-available.png';

const defaultBgRemoveFilter = {
  enabled: false,
  bgRemovedUrl: '',
  steps: []
};

const defaultBackgroundRemoveOptions = {
  model: 'u2net',
  alphaMatting: 'False',
  alphaMattingSettings: {
    foregroundThreshold: 240,
    backgroundThreshold: 10,
    erodeSize: 10
  },
  cropToVisiblePixels: 'False'
};

const clippingDefaults = {
  enabled: false,
  clipPath: shapes[0].path,
  scaleX: 1,
  scaleY: 1,
  originX: 'center',
  originY: 'center',
  rotateAngle: 0,
  clipPositionOffsets: {
    hOffset: 0,
    vOffset: 0
  }
}

const defaultInpaintForegroundFilter = {
  enabled: false,
  autoMask: true,
  maskUrl: '',
  expandMask: 30, // px
  fgRemovedUrl: ''
};

const imageFits = {
  original: 0,
  stretchToFit: 1,
  fillToBounds: 2,
  fitAndCrop: 3,
  crop: 4,
  clipToBounds: 5
}

const imageSourceTypes = {
  library: 'Library',
  unsplash: 'Unsplash',
  text2image: 'AI Image'
}

const galleryOptions = [
  imageSourceTypes.library,
  imageSourceTypes.unsplash,
  imageSourceTypes.text2image
]

module.exports = {
  imageNotAvailablePath,
  defaultBgRemoveFilter,
  defaultBackgroundRemoveOptions,
  clippingDefaults,
  defaultInpaintForegroundFilter,
  imageFits,
  imageSourceTypes,
  galleryOptions
};