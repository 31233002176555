const { forOwn, has, isPlainObject, transform, isEqual, pick, omit, set, get } = require('lodash');
const { layers: layerTypes, mediaTypes } = require('../constants/layers');
const { variantPropertyPaths } = require('../constants/variants');

/*
    getDeepDiff(base,other,exclude)
    base (Object)- Base object
    other (Object)- Compare to object
    exclude (Array)- Properties that needs to be excluded from diff, values of these properties will be untouched.
    propertiesToRemove (Array)- Properties that needs to be removed from the result.
    Example
    INPUT:
    var obj1 = {
        isDirty:true,
        data:{text:'Hello',color:'blue'},
        deepObj:{obj:{name:"abc"}},
        obj1Function:function(){return 'I am function 1'}
    }

    var obj2 = {
        isDirty:true,
        data:{text:'Hi',color:'blue'},
        deepObj:{obj:{name:"abc"}},
        obj1Function:function(){return 'I am function 1'}
    }
    getDeepDiff(obj1,obj2)

    OUTPUT:
        {
            data:{text:'Hi'},
            obj1Function:function(){return 'I am function 1'}
        }

*/
const getDeepDiff = (base, object, excludeFromComp, propertiesToRemove) => {
  if (!object) throw new Error(`The object to be compared with should be an object: ${object}`);
  if (!base) return object;
  const result = transform(object, (result, value, key) => {
    if((propertiesToRemove && propertiesToRemove.length >= 0 && propertiesToRemove.includes(key))){
      return;
    }
    if (!has(base, key)) result[key] = value;

    //Add the excluded object directly
    if(excludeFromComp && excludeFromComp.length >= 0 && excludeFromComp.includes(key) || typeof(value) == 'function'){
      result[key] = value;
    } else if (!isEqual(value, base[key])) {
      //check if it is object else add directly
      result[key] = isPlainObject(value) && isPlainObject(base[key]) ? getDeepDiff(base[key], value, excludeFromComp, propertiesToRemove) : value;
    }
  });

  //remove undefined objects
  forOwn(base, (value, key) => {
    if (!has(object, key)) delete result[key];
  });
  return result;
}

/*
    Gets list of all the keys in the object
*/

const getKeys = (a) => {
  let res = [];
  for (let key of Object.keys(a)) {
    res.push(key);
    if (typeof a[key] == 'object' && a[key] != null) {
      res.push(...getKeys(a[key]))
    }
  }
  return res;
}

/*
  Fetches the properties in 1 object based on the structure of second object. Stores the final result in a referenced param
*/
const recursiveExtract = (source, target, result) => {
  for (const key in target) {
    if (typeof target[key] === 'object' && !Array.isArray(target[key])) {
      // Recursively handle nested structures
      if (source[key] && typeof source[key] === 'object') {
        result[key] = {};
        recursiveExtract(source[key], target[key], result[key]);
      }
    } else {
      // Leaf property, fetch its value from the source object
      if (Array.isArray(source[key])) {
        result[key] = JSON.parse(JSON.stringify(source[key]));
      } else {
        result[key] = source[key];
      }
    }
  }
};

/*
    Object.assign's alternative for deep assign

    deepAssign(base,object)
    base - assign To Object
    object - assign From Object

    Example
    INPUT:
    var obj1 = {
        isDirty:true,
        data:{text:'Hello',color:'blue'},
        deepObj:{obj:{name:"abc"}},
        obj1Function:function(){return 'I am function 1'}
    }

    var obj2 = {
        isDirty:true,
        data:{text:'Hi',color:'red'},
        deepObj:{obj:{name:"abc"}},
        obj1Function:function(){return 'I am function 2'},
        isDirty:true
    }
    deepAssign(obj2,obj1)

    OUTPUT:
        {
          isDirty:true,
          data:{text:'Hello',color:'blue'},
          deepObj:{obj:{name:"abc"}},
          obj1Function:function(){return 'I am function 1'}
       }

*/

const isObject = (item)=> {
  return (item && typeof item === 'object' && !Array.isArray(item));
}
const deepAssign = (target, ...sources)=> {
  if (!sources.length) return target;
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }else{
          target[key] = Object.assign({}, target[key])
        }
        deepAssign(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepAssign(target, ...sources);
}

// Speaker image,subtitle need to be at top. Hence, other layers will be added at index below them.
const addLayerToLayersObject = (type, layers, data) => {
  var insertAtIndex = layers.filter(x => [layerTypes.subtitle].includes(x.type)).length;
  layers.splice(insertAtIndex, 0, data);
  return insertAtIndex;
};

const getLoaderTextObjectData = (isHidden, type) => {
  const textLoaderData = {
    visible: !isHidden,
    evented: false,
    selectable: false,
    objectCaching: false,
    textBackgroundColor: '#FFFFFF',
    fill: '#000000',
    fontFamily: 'Roboto',
    fontSize: 24,
    textAlign: 'center',
    width: 300
  }
  if (type) {
    textLoaderData[`is${type}Loader`] = true;
  } else {
    textLoaderData.isLoader = true;
  }
  return textLoaderData;
}

const getComputedLayer = (layers, ad, layerId) => {
  const layer = layers.find(l => l.id === layerId);
  let computedLayer = JSON.parse(JSON.stringify(layer));
  if (ad.localLayers && ad.localLayers.length > 0) {
    const localLayer = ad.localLayers.find(l => l.id === layerId);
    deepAssign(computedLayer, localLayer);
  }
  return computedLayer;
};

function replaceWithCDN(srcUrl) {
  const s3Url = `${process.env.VUE_APP_STORAGE_URL}/AllFiles/Uploads/`;
  const cdnUrl = process.env.VUE_APP_CB_GALLERY_URL;

  if (srcUrl && srcUrl.startsWith(s3Url) && cdnUrl) {
    const replacedUrl = srcUrl.replace(s3Url, cdnUrl);
    return replacedUrl;
  }

  return srcUrl;
};

const replaceMediaUrlWithCdn = (media, mediaType) => {
  if (mediaType === mediaTypes.image || mediaType === mediaTypes.aiGeneratedImage) {
    media[mediaType] && media[`${mediaType}s`].forEach(image => {
      image.imageUrl = replaceWithCDN(image.imageUrl);
    });
  } else if (mediaType === mediaTypes.video) {
    media.videos && media.videos.forEach(video => {
      video.url = replaceWithCDN(video.url);
    });
  } else {
    media.audios && media.audios.forEach(audio => {
      audio.url = replaceWithCDN(audio.url);
    });
  }
};

const getInsertAtIndex = function(layers, canvasLayers, insertAtLayerId, insertingLayerId) {
  let adLayerIndex = 0;
  for(let i = layers.length - 1; i >= 0; i--) {
    let insertingLayerIndex = layers.findIndex(layer => layer.id === insertingLayerId);
    let insertAtLayerIndex = layers.findIndex(layer => layer.id === insertAtLayerId);
    // if dropping the layer down from the current position
    if(insertAtLayerIndex > insertingLayerIndex) {
      if(layers[i].id === insertAtLayerId) break;
    }
    let fabricObject = canvasLayers.findIndex(obj => obj.layerId === layers[i].id);
    // As we will be reordering the canvas layers we will only count those
    if(fabricObject !== -1) {
      if(layers[i].type !== layerTypes.background && layers[i].type !== layerTypes.border && !(layers[i].id === insertingLayerId)) {
        adLayerIndex++;
      }
    }
    if(layers[i].id === insertAtLayerId) break;
  }
  return adLayerIndex;
}

// This function returns the filtered data with supported properties and unsupported properties
const filterProperties = (layer, supportedFocusModeProperties, commonProperties) => {
  if(!layer || !supportedFocusModeProperties) return;
  // Filter the data with supported properties and unsupported properties
  const filteredDataWithSupportedProperties = pick(layer, supportedFocusModeProperties);
  const filteredDataWithoutSupportedProperties = omit(layer, supportedFocusModeProperties);
  // Add the common properties to the filtered data with supported properties
  if(commonProperties) {
    for(let key of commonProperties) {
      const originalValue = get(layer, key);
      if(originalValue) {
        set(filteredDataWithSupportedProperties, key, originalValue);
      }
    }
  }

  return {
    filteredDataWithSupportedProperties,
    filteredDataWithoutSupportedProperties
  };
}

const roundOffPropertiesInObject = (object, precision) => {
  const multiplicationFactor = 10 ** precision;
  forOwn(object, (value, key) => {
    if (!isNaN(value)) {
      object[key] = Math.round((value + Number.EPSILON) * multiplicationFactor) / multiplicationFactor;
    }
  });
};

const getAllPathsInObject = (object, currentPath = '') => {
  let paths = [];

  for (let key in object) {
    let newPath = currentPath ? `${currentPath}.${key}` : key;
    if (typeof object[key] === 'object' && object[key] !== null && !Array.isArray(object[key])) {
      paths = paths.concat(getAllPathsInObject(object[key], newPath));
    } else {
      paths.push(newPath);
    }
  }

  return paths;
};

const flattenObject = (obj, prefix = '') => {
  let result = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (isPlainObject(value)) {
      Object.assign(result, flattenObject(value, newKey));
    } else {
      result[newKey] = value;
    }
  }

  return result;
}

const sanitizeNumber = (str, currentValue) => {
  if(currentValue) {
    return str !== currentValue ? str.replace(/[^\d.-]/g, '') : '0';
  }
  return str.replace(/[^\d.-]/g, '');
}

const getIsImageRegenerationRequired = (variants) => {
  return variants.some(variant =>
    variant.layers.some(l => {
      const callRequiredConfig = l.config.find(c => c.path == variantPropertyPaths.aiImageGenerationApiCallRequired);
      return callRequiredConfig && callRequiredConfig.value == true;
    })
  );
}

module.exports = {
  getDeepDiff,
  getKeys,
  recursiveExtract,
  deepAssign,
  addLayerToLayersObject,
  getLoaderTextObjectData,
  getComputedLayer,
  replaceMediaUrlWithCdn,
  getInsertAtIndex,
  filterProperties,
  roundOffPropertiesInObject,
  getAllPathsInObject,
  flattenObject,
  sanitizeNumber,
  replaceWithCDN,
  getIsImageRegenerationRequired
};
