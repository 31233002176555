/**
 * Initializes ffmpeg in-browser - https://github.com/ffmpegwasm/ffmpeg.wasm#usage
 * This method is called once when download video is invoked
 * @returns ffmpeg instance
 */
// AY TODO: Would be ideal to move this out to "Encoder" functionality

var ffmpegLogs = [];
var setLogging = false;

async function initFFmpeg() {
  // FFmpeg instance is globally added using script tag in the index.html page
  // https://github.com/ffmpegwasm/ffmpeg.wasm#installation > "Browser"

  const { createFFmpeg } = FFmpeg;
  var ffmpeg = createFFmpeg({ log: false });
  // eslint-disable-next-line
  console.log('Loading ffmpeg-core.js');
  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }
  ffmpeg.setLogger(({ type, message }) => {
    if (!setLogging) {
      return;
    }
    ffmpegLogs.push(message);
  });
  return ffmpeg;
}

async function getVideoFPS(ffmpeg, file) {
  const { fetchFile } = FFmpeg;

  setFfmpegLogging(true);

  const data = await fetchFile(file);
  ffmpeg.FS('writeFile', file.name ?? 'input.mp4', data);

  await ffmpeg.run('-i', file.name ?? 'input.mp4');

  const logs = getFfmpegLogs();

  let fps = 0;

  for (let i = 0; i < logs.length; i++) {
    const fpsMatch = logs[i].match(/, (\d+(\.\d+)?) fps,/);

    if (fpsMatch) {
      fps = parseFloat(fpsMatch[1]);
      break;
    }
  };

  setFfmpegLogging(false);
  clearFfmpegLogs();

  return fps;
}

async function convertVideoFPS(ffmpeg, file, fps, progressCallback) {
  const outputFileName = 'output.mp4';

  setFfmpegLogging(true);

  setProgressInLogging(ffmpeg, progressCallback, fps);

  let ffmpegCommand = ['-i', file.name ?? 'input.mp4'];

  // Re-encode video to avoid stream sync issues
  ffmpegCommand.push('-c:v', 'libx264');

  // Re-encode audio to avoid stream sync issues
  ffmpegCommand.push('-c:a', 'aac');

  // Use proper crf value to ensure output quality doesn't go down
  ffmpegCommand.push('-crf', '17');

  ffmpegCommand.push('-r', fps.toString(), outputFileName);

  await ffmpeg.run(...ffmpegCommand);

  const outputData = ffmpeg.FS('readFile', outputFileName);

  const blob = new Blob([outputData.buffer], { type: 'video/mp4' });

  setFfmpegLogging(false);
  clearFfmpegLogs();

  return blob;
}

async function setProgressInLogging(ffmpeg, progressCallback, fps) {
  let totalDuration = 0;

  ffmpeg.setLogger(({ type, message }) => {
    if (type == 'fferr') {
      if (message.includes('Duration:')) {
        const durationMatch = message.match(/Duration: (\d+):(\d+):(\d+\.\d+)/);
        if (durationMatch) {
          const [hours, minutes, seconds] = durationMatch.slice(1).map(parseFloat);
          totalDuration = hours * 3600 + minutes * 60 + seconds;
        }
      } else if (message.includes('time=')) {
        const timeMatch = message.match(/time=(\d+):(\d+):(\d+\.\d+)/);
        if (timeMatch) {
          const [hours, minutes, seconds] = timeMatch.slice(1).map(parseFloat);
          const currentTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

          if (totalDuration > 0) {
            const progress = (currentTimeInSeconds / totalDuration) * 100;

            if (progressCallback) {
              progressCallback({ progress, fps });
            }
          }
        }
      }
    }
  })
}

const setFfmpegLogging = (value) => {
  setLogging = value;
}

function getFfmpegLogs() {
  return ffmpegLogs;
}

function clearFfmpegLogs() {
  ffmpegLogs = [];
}

module.exports = {
  initFFmpeg,
  setFfmpegLogging,
  getFfmpegLogs,
  clearFfmpegLogs,
  getVideoFPS,
  convertVideoFPS
};