const imageGenerationModelValuesMappings = [
  { key: 'Flux', name: 'Alpha', value: 1 },
  { key: 'Sana', name: 'Beta', value: 2 },
  { key: 'Ideogram', name: 'Gamma', value: 3 },
  { key: 'Dalle', name: 'Delta', value: 4 }
];

const imageGenerationModelNames = Object.fromEntries(
  imageGenerationModelValuesMappings.map(({ key, name }) => [key, name])
);

const imageGenerationModelNameValueMapping = Object.fromEntries(
  imageGenerationModelValuesMappings.map(({ name, value }) => [name, value])
);

const imageGenerationModels = imageGenerationModelValuesMappings.map(({ name }) => name);

const aiImageStyles = {
  ICON: 'icon',
  REALISTIC: 'realistic',
  CLIPART: 'clipart',
  INFOGRAPHIC: 'infographic',
  LINEART: 'lineart',
  COMIC: 'comic'
};

module.exports = {
  imageGenerationModels,
  aiImageStyles,
  imageGenerationModelNames,
  imageGenerationModelValuesMappings,
  imageGenerationModelNameValueMapping
};
